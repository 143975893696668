import { css } from '@emotion/core';

export const styMusicFloating = css`
  .hide {
    display: none;
  }

  .btn-music {
    position: fixed;
    bottom: 24px;
    width: 40px;
    height: 40px;
    z-index: 999;
    left: 24px;
    animation: pulse 2s ease infinite;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: rgba(255, 0, 0, 0.4);
    color: #fff;
    text-align: center;
    font-size: 22px;
  }

  .btn-music i {
    vertical-align: middle;
    padding-bottom: 1px;
  }

  @media screen and (max-width: 420px) {
    .btn-music {
      left: 10px;
    }
  }

  @media screen and (max-width: 360px) {
    .btn-music {
      left: 10px;
    }
  }
`;

export const styWrapperModal = css`
  .modal-dialog {
    display: flex;
    justify-content: center;
  }

  .modal {
    display: block;
    width: 100%;
    background: rgba(44, 62, 80, 0.8);
  }

  .modal-content {
    width: 90%;
  }

  .sub-title {
    text-align: center;
    font-size: 20px;
  }

  .title {
    font-family: 'Cookie', cursive;
    text-align: center;
    font-size: 50px;
    margin-bottom: 0px;
  }
`;
