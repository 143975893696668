import React, { Fragment, useState } from 'react';
import MusicBacksound from '@assets/music/I Wanna Grow Old With You - Westlife.mp3';

import { styMusicFloating } from './styles';

function FloatingMusic() {
  const [play, setPlay] = useState(true);

  const toggleMusic = () => {
    const musicEL = document.getElementById('musicEL');
    /**
     * This function built-in with html5 function
     * doc: https://www.w3schools.com/tags/ref_av_dom.asp
     */
    if (play) {
      musicEL.pause();
    } else {
      musicEL.play();
    }

    setPlay(!play);
  };

  return (
    <Fragment>
      <div css={styMusicFloating}>
        <audio id="musicEL" loop className="hide" preload="auto">
          <source src={MusicBacksound} type="audio/mpeg" />
        </audio>

        <div onClick={toggleMusic} className="btn-music">
          <i className={play ? 'icon-volume' : 'icon-mute'}></i>
        </div>
      </div>
    </Fragment>
  );
}

export default FloatingMusic;
